* {
  background: scroll none center top no-repeat transparent;
  border: 0 solid transparent;
  border-collapse: separate;
  border-spacing: 0;
  cursor: auto;
  float: none;
  font-family: "Ubuntu", "Tahoma", "Arial", sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-variant: normal;
  font-weight: normal;
  color: #333c43;
  line-height: 110%;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: justify;
  text-decoration: none;
  text-transform: none;
  vertical-align: baseline;
  white-space: normal;
  word-spacing: 0;
  z-index: 0;
}

html, body {
  background-color: #fff;
  width: 100%;
}

body {
  text-align: center;
  background-color: #ecf0f1;
}

.btn {
  /*background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNgYGBoAAAAhQCBRcqBlwAAAABJRU5ErkJggg==") repeat 0% 0% transparent;*/
  margin: 0px;
  height: 0px;
  width: auto;
  display: inline-block;
  padding: 8px 18px 22px;
  border-radius: 2px;
  border: 1px solid #ffc059;
  outline: 0 none;
  resize: none;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: background-color 0.2s ease-out 0s;
  -moz-transition: background-color 0.2s ease-out 0s;
  -o-transition: background-color 0.2s ease-out 0s;
  transition: background-color 0.2s ease-out 0s;
}/*
  button.btn { padding: 6px 0px 24px; }
  input.btn { padding: 5px 0px 25px; }*/
  .btn:hover {
    background-color: #000;
  }
  .btn-45deg {
    padding: 0;
    height: 30px;
    width: 30px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

.ffuc { font-family: "Ubuntu Condensed", sans-serif; }

.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.bgptl { background-position: top left; }
.bgptc { background-position: top center; }
.bgptr { background-position: top right; }
.bgpcl { background-position: center left; }
.bgpcc { background-position: center center; }
.bgpcr { background-position: center right; }
.bgpbl { background-position: bottom left; }
.bgpbc { background-position: bottom center; }
.bgpbr { background-position: bottom right; }

.bgrr { background-repeat: repeat; }
.bgrnr { background-repeat: no-repeat; }
.bgrrx { background-repeat: repeat-x; }
.bgrry { background-repeat: repeat-y; }

.bgzcvr { background-size: cover; }
.bgzcntn { background-size: contain; }

.bgc0 { background-color: #000; }
.bgc0_50p, .bgc0_before_50p:before, .bgc0_after_50p:after { background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNgYGBoAAAAhQCBRcqBlwAAAABJRU5ErkJggg=="); background-repeat: repeat; }
.bgc0_75p, .bgc0_before_75p:before, .bgc0_after_75p:after { background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNgYGDYDwAAxADA5GrqEwAAAABJRU5ErkJggg=="); background-repeat: repeat; }
.bgcw { background-color: #fff; }
.bgcorange { background-color: #f38612; }
.bgcyellow { background-color: #f1c40f; }
.bgcred { background-color: #c1272c; }
.bgcf6 { background-color: #f6f6f6; }
.bgcea { background-color: #eaeaea; }
.bgce0 { background-color: #e0e0e0; }
.bgcd5 { background-color: #d5d5d5; }
.bgcc { background-color: #ccc; }
.bgcgreen { background-color: #00c31a; }
.bgcblue { background-color: #1666a8; }
.bgclblue { background-color: #ecf0f1; }
.bgcbeige { background-color: #fefbec; }

.cw, .cw_a, .cw_a * { color: #fff; }
.cw_i, .cw_a_i, .cw_a_i * { color: #fff !important; }
.cred, label .required { color: #c1272c; }
.corange { color: #f39c12; }
.cyellow { color: #f1c40f; }
.cgray { color: #808080; }
.cgreen { color: #00c31a; }
.cblue { color: #1666a8; }
.clblue { color: #ecf0f1; }
.cbeige { color: #fefbec; }

.dn { display: none; }
.dn_i, .hidden { display: none !important; }
.db { display: block; }
.dib { display: inline-block; }
.dt { display: table; }
.dtr { display: table-row; }
.dtc { display: table-cell; }

/* Flex */
.dfdr { display: flex; flex-direction: row; }
.dfdrr { display: flex; flex-direction: row-reverse; }
.dfdc { display: flex; flex-direction: column; }
.dfdcr { display: flex; flex-direction: column-reverse; }
.difdr { display: inline-flex; flex-direction: row; }
.difdrr { display: inline-flex; flex-direction: row-reverse; }
.difdc { display: inline-flex; flex-direction: column; }
.difdcr { display: inline-flex; flex-direction: column-reverse; }
.fwn { flex-wrap: nowrap; }
.fww { flex-wrap: wrap; }
.fwwr { flex-wrap: wrap-reverse; }
.fjcs { justify-content: flex-start; }
.fjce { justify-content: flex-end; }
.fjcc { justify-content: center; }
.fjcsa { justify-content: space-around; }
.fjcsb { justify-content: space-between; }
.fais { align-items: flex-start; }
.faie { align-items: flex-end; }
.faic { align-items: center; }
.faib { align-items: baseline; }
.faist { align-items: stretch; }
.facs { align-content: flex-start; }
.face { align-content: flex-end; }
.facc { align-content: center; }
.facsa { align-content: space-around; }
.facsb { align-content: space-between; }
.facst { align-content: stretch; }
.fass { align-self: flex-start; }
.fase { align-self: flex-end; }
.fasc { align-self: center; }
.fasb { align-self: baseline; }
.fasst { align-self: stretch; }

.curp, .curp_a, .curp_a * { cursor: pointer; }
.curp_i, .curp_a_i, .curp_a_i * { cursor: pointer !important; }
.curd, .curd_a, .curd_a * { cursor: default; }
.curd_i, .curd_a_i, .curd_a_i * { cursor: default !important; }

.h0 { height: 0; }
.h100p, .hfull { height: 100%; }
.hauto { height: auto; }

.w0 { width: 0; }
.w1 { width: 1px; }
.w260 { width: 260px; }
.w280 { width: 280px; }
.w350 { width: 350px; }
.w400 { width: 400px; }
.w480 { width: 480px; }
.w570 { width: 570px; }
.w640 { width: 640px; }
.w750 { width: 750px; }
.w800 { width: 800px; }
.w860 { width: 860px; }
.w960 { width: 960px; }
.w1024 { width: 1024px; }
.w1048 { width: 1048px; }
.w1100 { width: 1100px; }
.w1200 { width: 1200px; }
.w5p { width: 5%; }
.w7p { width: 7%; }
.w10p { width: 10%; }
.w12p { width: 12.5%; }
.w15p { width: 15%; }
.w20p { width: 20%; }
.w25p { width: 25%; }
.w33p { width: 33.3333%; }
.w35p { width: 35%; }
.w40p { width: 40%; }
.w50p { width: 50%; }
.w60p { width: 60%; }
.w66p { width: 66.6666%; }
.w70p { width: 70%; }
.w75p { width: 75%; }
.w80p { width: 80%; }
.w85p { width: 85%; }
.w88p { width: 87.5%; }
.w90p { width: 90%; }
.w93p { width: 93%; }
.w95p { width: 95%; }
.w100p, .wfull { width: 100%; }
.wauto { width: auto; }

.m0auto, .m0a, .mar0a { margin: 0 auto; }
.m0auto_i, .m0a_i, .mar0a_i { margin: 0 auto !important; }
.mauto0, .ma0 { margin: auto 0; }
.mauto0_i, .ma0_i { margin: auto 0 !important; }
.mauto, .ma { margin: auto; }
.mauto_i, .ma_i { margin: auto !important; }
.mtauto, .mta { margin-top: auto; }
.mtauto_i, .mta_i { margin-top: auto !important; }
.mlauto, .mla { margin-left: auto; }
.mlauto_i, .mla_i { margin-left: auto !important; }
.mrauto, .mra { margin-right: auto; }
.mrauto_i, .mra_i { margin-right: auto !important; }
.mbauto, .mba { margin-bottom: auto; }
.mbauto_i, .mba_i { margin-bottom: auto !important; }
.m0 { margin: 0; }
.m0_i { margin: 0 !important; }
.mt0 { margin-top: 0; }
.mt0_i { margin-top: 0 !important; }
.ml0 { margin-left: 0; }
.ml0_i { margin-left: 0 !important; }
.mr0 { margin-right: 0; }
.mr0_i { margin-right: 0 !important; }
.mb0 { margin-bottom: 0; }
.mb0_i { margin-bottom: 0 !important; }
.m1 { margin: 1px; }
.mt1 { margin-top: 1px; }
.ml1 { margin-left: 1px; }
.mr1 { margin-right: 1px; }
.mb1 { margin-bottom: 1px; }
.m2 { margin: 2px; }
.mt2 { margin-top: 2px; }
.ml2 { margin-left: 2px; }
.mr2 { margin-right: 2px; }
.mb2 { margin-bottom: 2px; }
.m3 { margin: 3px; }
.mt3 { margin-top: 3px; }
.ml3 { margin-left: 3px; }
.mr3 { margin-right: 3px; }
.mb3 { margin-bottom: 3px; }
.m4 { margin: 4px; }
.mt4 { margin-top: 4px; }
.ml4 { margin-left: 4px; }
.mr4 { margin-right: 4px; }
.mb4 { margin-bottom: 4px; }
.m5 { margin: 5px; }
.mt5 { margin-top: 5px; }
.ml5 { margin-left: 5px; }
.mr5 { margin-right: 5px; }
.mb5 { margin-bottom: 5px; }
.m7 { margin: 7px; }
.mt7 { margin-top: 7px; }
.ml7 { margin-left: 7px; }
.mr7 { margin-right: 7px; }
.mb7 { margin-bottom: 7px; }
.m10 { margin: 10px; }
.mt10 { margin-top: 10px; }
.ml10 { margin-left: 10px; }
.mr10 { margin-right: 10px; }
.mb10 { margin-bottom: 10px; }
.m13 { margin: 13px; }
.mt13 { margin-top: 13px; }
.ml13 { margin-left: 13px; }
.mr13 { margin-right: 13px; }
.mb13 { margin-bottom: 13px; }
.m15 { margin: 15px; }
.mt15 { margin-top: 15px; }
.ml15 { margin-left: 15px; }
.mr15 { margin-right: 15px; }
.mb15 { margin-bottom: 15px; }
.m20 { margin: 20px; }
.mt20 { margin-top: 20px; }
.ml20 { margin-left: 20px; }
.mr20 { margin-right: 20px; }
.mb20 { margin-bottom: 20px; }
.m25 { margin: 25px; }
.mt25 { margin-top: 25px; }
.ml25 { margin-left: 25px; }
.mr25 { margin-right: 25px; }
.mb25 { margin-bottom: 25px; }
.m30 { margin: 30px; }
.mt30 { margin-top: 30px; }
.ml30 { margin-left: 30px; }
.mr30 { margin-right: 30px; }
.mb30 { margin-bottom: 30px; }
.m-1 { margin: -1px; }
.mt-1 { margin-top: -1px; }
.ml-1 { margin-left: -1px; }
.mr-1 { margin-right: -1px; }
.mb-1 { margin-bottom: -1px; }
.m-2 { margin: -2px; }
.mt-2 { margin-top: -2px; }
.ml-2 { margin-left: -2px; }
.mr-2 { margin-right: -2px; }
.mb-2 { margin-bottom: -2px; }
.m-3 { margin: -3px; }
.mt-3 { margin-top: -3px; }
.ml-3 { margin-left: -3px; }
.mr-3 { margin-right: -3px; }
.mb-3 { margin-bottom: -3px; }
.m-4 { margin: -4px; }
.mt-4 { margin-top: -4px; }
.ml-4 { margin-left: -4px; }
.mr-4 { margin-right: -4px; }
.mb-4 { margin-bottom: -4px; }
.m-5 { margin: -5px; }
.mt-5 { margin-top: -5px; }
.ml-5 { margin-left: -5px; }
.mr-5 { margin-right: -5px; }
.mb-5 { margin-bottom: -5px; }
.m-7 { margin: -7px; }
.mt-7 { margin-top: -7px; }
.ml-7 { margin-left: -7px; }
.mr-7 { margin-right: -7px; }
.mb-7 { margin-bottom: -7px; }
.m-10 { margin: -10px; }
.mt-10 { margin-top: -10px; }
.ml-10 { margin-left: -10px; }
.mr-10 { margin-right: -10px; }
.mb-10 { margin-bottom: -10px; }
.m-13 { margin: -13px; }
.mt-13 { margin-top: -13px; }
.ml-13 { margin-left: -13px; }
.mr-13 { margin-right: -13px; }
.mb-13 { margin-bottom: -13px; }
.m-15 { margin: -15px; }
.mt-15 { margin-top: -15px; }
.ml-15 { margin-left: -15px; }
.mr-15 { margin-right: -15px; }
.mb-15 { margin-bottom: -15px; }
.m-20 { margin: -20px; }
.mt-20 { margin-top: -20px; }
.ml-20 { margin-left: -20px; }
.mr-20 { margin-right: -20px; }
.mb-20 { margin-bottom: -20px; }
.m-25 { margin: -25px; }
.mt-25 { margin-top: -25px; }
.ml-25 { margin-left: -25px; }
.mr-25 { margin-right: -25px; }
.mb-25 { margin-bottom: -25px; }
.m-30 { margin: -30px; }
.mt-30 { margin-top: -30px; }
.ml-30 { margin-left: -30px; }
.mr-30 { margin-right: -30px; }
.mb-30 { margin-bottom: -30px; }

.p0 { padding: 0 !important; }
.pt0 { padding-top: 0 !important; }
.pl0 { padding-left: 0 !important; }
.pr0 { padding-right: 0 !important; }
.pb0 { padding-bottom: 0 !important; }
.p1 { padding: 1px !important; }
.pt1 { padding-top: 1px !important; }
.pl1 { padding-left: 1px !important; }
.pr1 { padding-right: 1px !important; }
.pb1 { padding-bottom: 1px !important; }
.p2 { padding: 2px !important; }
.pt2 { padding-top: 2px !important; }
.pl2 { padding-left: 2px !important; }
.pr2 { padding-right: 2px !important; }
.pb2 { padding-bottom: 2px !important; }
.p3 { padding: 3px !important; }
.pt3 { padding-top: 3px !important; }
.pl3 { padding-left: 3px !important; }
.pr3 { padding-right: 3px !important; }
.pb3 { padding-bottom: 3px !important; }
.p4 { padding: 4px !important; }
.pt4 { padding-top: 4px !important; }
.pl4 { padding-left: 4px !important; }
.pr4 { padding-right: 4px !important; }
.pb4 { padding-bottom: 4px !important; }
.p5 { padding: 5px !important; }
.pt5 { padding-top: 5px !important; }
.pl5 { padding-left: 5px !important; }
.pr5 { padding-right: 5px !important; }
.pb5 { padding-bottom: 5px !important; }
.p7 { padding: 7px !important; }
.pt7 { padding-top: 7px !important; }
.pl7 { padding-left: 7px !important; }
.pr7 { padding-right: 7px !important; }
.pb7 { padding-bottom: 7px !important; }
.p10 { padding: 10px !important; }
.pt10 { padding-top: 10px !important; }
.pl10 { padding-left: 10px !important; }
.pr10 { padding-right: 10px !important; }
.pb10 { padding-bottom: 10px !important; }
.p13 { padding: 13px !important; }
.pt13 { padding-top: 13px !important; }
.pl13 { padding-left: 13px !important; }
.pr13 { padding-right: 13px !important; }
.pb13 { padding-bottom: 13px !important; }
.p15 { padding: 15px !important; }
.pt15 { padding-top: 15px !important; }
.pl15 { padding-left: 15px !important; }
.pr15 { padding-right: 15px !important; }
.pb15 { padding-bottom: 15px !important; }
.p20 { padding: 20px !important; }
.pt20 { padding-top: 20px !important; }
.pl20 { padding-left: 20px !important; }
.pr20 { padding-right: 20px !important; }
.pb20 { padding-bottom: 20px !important; }
.p25 { padding: 25px !important; }
.pt25 { padding-top: 25px !important; }
.pl25 { padding-left: 25px !important; }
.pr25 { padding-right: 25px !important; }
.pb25 { padding-bottom: 25px !important; }
.p30 { padding: 30px !important; }
.pt30 { padding-top: 30px !important; }
.pl30 { padding-left: 30px !important; }
.pr30 { padding-right: 30px !important; }
.pb30 { padding-bottom: 30px !important; }

.vat { vertical-align: top; }
.vam { vertical-align: middle; }
.vab { vertical-align: bottom; }
.vabl { vertical-align: baseline; }

.tal { text-align: left; }
.tac { text-align: center; }
.tar { text-align: right; }

.fsi { font-style: italic; }

.fz0 { font-size: 0; }
.fz0_i { font-size: 0 !important; }
.fz10, .fz10_a, .fz10_a * { font-size: 10px; }
.fz12, .fz12_a, .fz12_a * { font-size: 12px; }
.fz14, .fz14_a, .fz14_a * { font-size: 14px; }
.fz15 { font-size: 15px; }
.fz16 { font-size: 16px; }
.fz18 { font-size: 18px; }
.fz20 { font-size: 20px; }
.fz22 { font-size: 22px; }
.fz24 { font-size: 24px; }
.fz26 { font-size: 26px; }
.fz28 { font-size: 28px; }
.fz30 { font-size: 30px; }
.fz32 { font-size: 32px; }
.fz34 { font-size: 34px; }
.fz36 { font-size: 36px; }
.fz38 { font-size: 38px; }
.fz40 { font-size: 40px; }
.fz42 { font-size: 42px; }
.fz44 { font-size: 44px; }
.fz46 { font-size: 46px; }
.fz48 { font-size: 48px; }
.fz50 { font-size: 50px; }
.fz52 { font-size: 52px; }
.fz54 { font-size: 54px; }
.fz56 { font-size: 56px; }
.fz58 { font-size: 58px; }
.fz60 { font-size: 60px; }

.fw700, .fw700_a, .fw700_a *, .bold, .bold * { font-weight: 700; }
.fw500, .fw500_a, .fw500_a *, .semibold, .semibold * { font-weight: 500; }
.fw300, .fw300_a, .fw300_a *, .light, .light * { font-weight: 300; }

.inherit { font-family: inherit; font-size: inherit; font-weight: inherit; font-style: inherit; text-transform: inherit; }
.fzinherit { font-size: inherit; }

.caps { text-transform: uppercase; }

.tdn, .tdn_a, .tdn_a *, .tdn_h:hover, .tdn_a_h:hover, .tdn_a_h:hover * { text-decoration: none; }
.tdn_i, .tdn_a_i, .tdn_a_i *, .tdn_h_i:hover, .tdn_a_h_i:hover, .tdn_a_h_i:hover * { text-decoration: none !important; }
.tdu, .tdu_a, .tdu_a *, .tdu_h:hover, .tdu_a_h:hover, .tdu_a_h:hover * { text-decoration: underline; }
.tdu_i, .tdu_a_i, .tdu_a_i *, .tdu_h_i:hover, .tdu_a_h_i:hover, .tdu_a_h_i:hover * { text-decoration: underline !important; }
.tdlth, .tdlth_a, .tdlth_a *, .tdlth_h:hover, .tdlth_a_h:hover, .tdlth_a_h:hover * { text-decoration: line-through; }
.tdlth_i, .tdlth_a_i, .tdlth_a_i *, .tdlth_h_i:hover, .tdlth_a_h_i:hover, .tdlth_a_h_i:hover * { text-decoration: line-through !important; }

.wsn { white-space: normal; }
.wsnw { white-space: nowrap; }
.wsp { white-space: pre; }
.wspl { white-space: pre-line; }
.wspw { white-space: pre-wrap; }

.ofh { overflow: hidden; }

.posr { position: relative; }
.posa { position: absolute; }
.posf { position: fixed; }

.z0 { z-index: 0; }
.z4 { z-index: 4; }
.z9 { z-index: 9; }
.z49 { z-index: 49; }
.z99 { z-index: 99; }
.z999 { z-index: 999; }
.z9999 { z-index: 9999; }

.t0 { top: 0; }
.t100p { top: 100%; }
.t-100p { top: -100%; }
.l0 { left: 0; }
.l100p { left: 100%; }
.l-100p { left: -100%; }
.r0 { right: 0; }
.r100p { right: 100%; }
.r-100p { right: -100%; }
.b0 { bottom: 0; }
.b100p { bottom: 100%; }
.b-100p { bottom: -100%; }

.fll { float: left; }
.flr { float: right; }

.brs0 { border-radius: 0px; }
.brs2 { border-radius: 2px; }
.brst2 { border-radius: 2px 2px 0px 0px; }
.brsl2 { border-radius: 2px 0px 0px 2px; }
.brsr2 { border-radius: 0px 2px 2px 0px; }
.brsb2 { border-radius: 0px 0px 2px 2px; }
.brs3 { border-radius: 3px; }
.brst3 { border-radius: 3px 3px 0px 0px; }
.brsl3 { border-radius: 3px 0px 0px 3px; }
.brsr3 { border-radius: 0px 3px 3px 0px; }
.brsb3 { border-radius: 0px 0px 3px 3px; }
.brs5 { border-radius: 5px; }
.brst5 { border-radius: 5px 5px 0px 0px; }
.brsl5 { border-radius: 5px 0px 0px 5px; }
.brsr5 { border-radius: 0px 5px 5px 0px; }
.brsb5 { border-radius: 0px 0px 5px 5px; }
.brs7 { border-radius: 7px; }
.brst7 { border-radius: 7px 7px 0px 0px; }
.brsl7 { border-radius: 7px 0px 0px 7px; }
.brsr7 { border-radius: 0px 7px 7px 0px; }
.brsb7 { border-radius: 0px 0px 7px 7px; }
.brs10 { border-radius: 10px; }
.brst10 { border-radius: 10px 10px 0px 0px; }
.brsl10 { border-radius: 10px 0px 0px 10px; }
.brsr10 { border-radius: 0px 10px 10px 0px; }
.brsb10 { border-radius: 0px 0px 10px 10px; }
.brs50p { border-radius: 50%; }

.brw0 { border-width: 0px; }
.brtw0 { border-top-width: 0px; }
.brlw0 { border-left-width: 0px; }
.brrw0 { border-right-width: 0px; }
.brbw0 { border-bottom-width: 0px; }
.brw1 { border-width: 1px; }
.brtw1 { border-top-width: 1px; }
.brlw1 { border-left-width: 1px; }
.brrw1 { border-right-width: 1px; }
.brbw1 { border-bottom-width: 1px; }
.brw2 { border-width: 2px; }
.brtw2 { border-top-width: 2px; }
.brlw2 { border-left-width: 2px; }
.brrw2 { border-right-width: 2px; }
.brbw2 { border-bottom-width: 2px; }
.brw3 { border-width: 3px; }
.brtw3 { border-top-width: 3px; }
.brlw3 { border-left-width: 3px; }
.brrw3 { border-right-width: 3px; }
.brbw3 { border-bottom-width: 3px; }
.brw4 { border-width: 4px; }
.brtw4 { border-top-width: 4px; }
.brlw4 { border-left-width: 4px; }
.brrw4 { border-right-width: 4px; }
.brbw4 { border-bottom-width: 4px; }
.brw5 { border-width: 5px; }
.brtw5 { border-top-width: 5px; }
.brlw5 { border-left-width: 5px; }
.brrw5 { border-right-width: 5px; }
.brbw5 { border-bottom-width: 5px; }
.brw7 { border-width: 7px; }
.brtw7 { border-top-width: 7px; }
.brlw7 { border-left-width: 7px; }
.brrw7 { border-right-width: 7px; }
.brbw7 { border-bottom-width: 7px; }
.brw10 { border-width: 10px; }
.brtw10 { border-top-width: 10px; }
.brlw10 { border-left-width: 10px; }
.brrw10 { border-right-width: 10px; }
.brbw10 { border-bottom-width: 10px; }

.brstn { border-style: none; }
.brsts { border-style: solid; }
.brstd { border-style: dashed; }

.brct { border-color: transparent; }
.brcw { border-color: #fff; }
.brcorange { border-color: #f38612; }
.brcred { border-color: #c1272c; }
.brcgreen { border-color: #00c31a; }
.brcblue { border-color: #1666a8; }
.brclblue { border-color: #ecf0f1; }
.brcf6 { border-color: #f6f6f6; }
.brcea { border-color: #eaeaea; }
.brcc { border-color: #ccc; }
.brc8 { border-color: #888; }
.brc0 { border-color: #000; }
.brcbeige { border-color: #fefbec; }

ul { list-style: none outside; }
a[href] { cursor: pointer; }
p { margin-bottom: 13px; }

/*
#top_panel, #bottom_panel {
  z-index: 99;
  height: 0;
  position: fixed;
  font-size: 0;
}
  #bottom_panel { bottom: 50px; height: 0px; }
  #top_panel .phone_order { top: 50px; display: inline-block; }
  #bottom_panel .to_top { display: inline-block; margin-top: -100%; */
/*bottom: 50px;*//*
 }
*/

.breadcrumbs * {
  font-size: 12px;
  font-style: italic;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.breadcrumbs > * {
  display: table-cell;
  vertical-align: top;
  margin-right: 3px;
  font-size: 0px;
  border-right: 6px solid transparent;
  padding-left: 3px;
  padding-right: 3px;
}
  .breadcrumbs > * > div {
    font-size: 0px;
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;

    -webkit-transform: skew(-45deg);
    -moz-transform: skew(-45deg);
    -ms-transform: skew(-45deg);
    -o-transform: skew(-45deg);
    transform: skew(-45deg);
  }
    .breadcrumbs > * > .link {
      /*background-color: #F5C797;*/
      -webkit-transition: background-color 0.2s ease-out 0s, border-color 0.2s ease-out 0s;
      -moz-transition: background-color 0.2s ease-out 0s, border-color 0.2s ease-out 0s;
      -o-transition: background-color 0.2s ease-out 0s, border-color 0.2s ease-out 0s;
      transition: background-color 0.2s ease-out 0s, border-color 0.2s ease-out 0s;
    }
      .breadcrumbs > *:hover > .link {
        background-color: #f38612 !important;
        border-color: #fff !important;
      }
    .breadcrumbs > * > a {
      -webkit-transition: color 0.2s ease-out 0s, text-decoration 0.2s ease-out 0s;
      -moz-transition: color 0.2s ease-out 0s, text-decoration 0.2s ease-out 0s;
      -o-transition: color 0.2s ease-out 0s, text-decoration 0.2s ease-out 0s;
      transition: color 0.2s ease-out 0s, text-decoration 0.2s ease-out 0s;
    }
      .breadcrumbs > *:hover > a {
        color: #fff !important;
      }

.form .form-error .errorMessage {
  padding: 5px;
  margin-top: -2px;
}
input[type*="text"], input[type*="tel"], input[type*="date"], input[type*="search"], input[type*="password"], textarea {
  border: 0px solid transparent;
  outline: 0px none transparent;
  overflow: hidden;
  resize: none;
  line-height: normal;
}

.udarenie {
  display: inline-block;
  width: 0;
  left: 3px;
  top: -1px;
}

.header-slider {
  overflow: hidden;
  font-size: 0px;

  height: 450px;
  margin-bottom: -450px;
  text-align: center;
}
  .header-slider-min {
    height: 200px;
    margin-bottom: -200px;
    background: url("/i/header-slide.jpg") center bottom -15px transparent;
  }
  .header-slider > div, .header-slider > img {
    height: 450px;
    background-color: #c0c0c0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    font-size: 0;

    position: absolute;
    width: 100%;
  }

.top_menu {
  /*background-color: #000*//*rgba(0,0,0,.75)*//*;*/
  /*background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNgYGDYDwAAxADA5GrqEwAAAABJRU5ErkJggg==") repeat 0% 0% transparent;*/
  z-index: 30;
}
  .top_menu .top_menu_ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
  }
  .top_menu .top_menu_ul > li {
    padding: 7px 8px;
    text-align: center;
  }
    .top_menu .top_menu_ul > li > a,
    .top_menu .top_menu_ul > li > a * {
      color: #fff;
      font-weight: 300;
      text-transform: uppercase;
      cursor: pointer;
    }
    .top_menu .top_menu_ul > li > a {
      display: inline-block;
      border-bottom: 2px solid transparent;
      font-size: 14px;
      padding: 2px 0px;
      white-space: nowrap;
      /*width: auto;*/

      -webkit-transition: color 0.2s ease-out 0s, border-color 0.2s ease-out 0s;
      -moz-transition: color 0.2s ease-out 0s, border-color 0.2s ease-out 0s;
      -o-transition: color 0.2s ease-out 0s, border-color 0.2s ease-out 0s;
      transition: color 0.2s ease-out 0s, border-color 0.2s ease-out 0s;
    }
    .top_menu .top_menu_ul > li > a * {
      font-size: 20px;

      -webkit-transition: color 0.2s ease-out 0s;
      -moz-transition: color 0.2s ease-out 0s;
      -o-transition: color 0.2s ease-out 0s;
      transition: color 0.2s ease-out 0s;
    }
    .top_menu .top_menu_ul > li > a:hover,
    .top_menu .top_menu_ul > li > a:hover *,
    .top_menu .top_menu_ul > li.active > a,
    .top_menu .top_menu_ul > li.active > a * {
      color: #f1c40f;
    }
    .top_menu .top_menu_ul > li > a:hover {
      border-bottom-color: #fff;
    }
    .top_menu .top_menu_ul > li.active > a {
      font-weight: 700;
      cursor: default;
    }
    .top_menu .top_menu_ul > li.active > a {
      border-bottom-color: #f1c40f;
    }
      .top_menu .top_menu_ul > li.active > a * {
        font-weight: 400;
        cursor: default;
      }
      .bgcorange .top_menu_ul > li > a,
      .top_menu_ul.bgcorange > li > a {
        font-weight: 700 !important;
      }
        .bgcorange .top_menu_ul > li > a *,
        .top_menu_ul.bgcorange > li > a * {
          font-weight: 400 !important;
        }
      .bgcorange .top_menu_ul > li > :hover,
      .top_menu_ul.bgcorange > li > :hover {
        color: #fff;
      }
      .bgcorange .top_menu_ul > li.active > *,
      .top_menu_ul.bgcorange > li.active > * {
        color: #fff;
        border-bottom-color: #fff;
      }
  .top_menu .top_menu_ul > li > ul {
    /*background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNgYGDYDwAAxADA5GrqEwAAAABJRU5ErkJggg==") repeat 0% 0% transparent;*/
    /*display: none;*/
    border: 0 none;
    left: auto;
    right: 0;
    /*margin: 0 0 0 -8px;*/
    height: 0;
    padding: 0;
    position: absolute;
    top: 40px;
    overflow: hidden;
    border-top: 0px solid #f39c12;
    cursor: default;

    -webkit-transition: height 0.2s ease-out 0s, border-top-width 0.2s ease-out 0s;
    -moz-transition: height 0.2s ease-out 0s, border-top-width 0.2s ease-out 0s;
    -o-transition: height 0.2s ease-out 0s, border-top-width 0.2s ease-out 0s;
    transition: height 0.2s ease-out 0s, border-top-width 0.2s ease-out 0s;
  }
    .top_menu .top_menu_ul > li:hover > ul, .top_menu .top_menu_ul > li > ul:hover {
      /*display: block;*/
      height: 112px; /* 27 x N (element count) + 4px */
      border-top-width: 3px;
    }
    .top_menu .top_menu_ul > li > ul > li {
      display: block;
      padding: 4px 8px;
      height: 19px;
    }
      .top_menu .top_menu_ul > li > ul > li > * {
        color: #fff;
        font-weight: 400;
        padding-bottom: 1px;
        border-bottom: 2px solid transparent;
        cursor: pointer;

        -webkit-transition: color 0.2s ease-out 0s, border-bottom-color 0.2s ease-out 0s;
        -moz-transition: color 0.2s ease-out 0s, border-bottom-color 0.2s ease-out 0s;
        -o-transition: color 0.2s ease-out 0s, border-bottom-color 0.2s ease-out 0s;
        transition: color 0.2s ease-out 0s, border-bottom-color 0.2s ease-out 0s;
      }
      .top_menu .top_menu_ul > li > ul > li > :hover {
        color: /*#ffc059*/ #f1c40f;
        border-bottom-color: #fff;
      }
      .top_menu .top_menu_ul > li > ul > li.active > * {
        color: /*#ffc059*/ #f1c40f;
        font-weight: 500;
        border-bottom-color: /*#ffc059*/ #f1c40f;
        cursor: default;
      }
    .top_menu .top_menu_ul > li.section_portfolio:hover > ul {
      height: 58px; /* 27 x N (element count) + 4px */
    }
    .top_menu .top_menu_ul > li.menu-sozdanie_sajtov:hover > ul {
      height: 31px; /* 27 x N (element count) + 4px */
    }

.slash {
  height: 370px;
  overflow: visible;
  z-index: 10;
}
  .slash_min {
    height: 150px !important;
  }
  .slash > div:first-child {
    width: 50%;
    height: /*410px*/370px;
    /*background-color: #000*//*rgba(0,0,0,.5)*//*;*/
    /*background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNgYGBoAAAAhQCBRcqBlwAAAABJRU5ErkJggg==") repeat 0% 0% transparent;*/
    position: absolute;
    padding-right: 130px;
  }
    .slash_min > div:first-child {
      height: 150px !important;
    }
    .slash > div:first-child > div {
      content: "";
      position: absolute;
      /*background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNgYGDwBQAAUgBOSj3U6wAAAABJRU5ErkJggg==");*/
      top: 0;
      overflow: hidden;
      background: transparent;
      width: 370px;
      height: 370px;/*
      -webkit-transform: skew(-45deg);
      -moz-transform: skew(-45deg);
      -ms-transform: skew(-45deg);
      -o-transform: skew(-45deg);
      transform: skew(-45deg);*/
      /*width: 0;
      height: 0;*/
      right: /*-410px*/-370px/*-185px*/;
      /*border-top: *//*410px*//*370px solid #000*//*rgba(0,0,0,.5)*//*;*/
      /*border-right: *//*410px*//*370px solid transparent;*/
    }
      .slash_min >div:first-child > div {
        height: 150px !important;
      }
      .slash > div:first-child > div:after {
        content: "";
        /*background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNgYGBoAAAAhQCBRcqBlwAAAABJRU5ErkJggg==") repeat 0% 0% transparent;*/
        background-size: 100% 100%;
        position: absolute;
        width: 370px;
        height: 370px;
        -webkit-transform: skew(-45deg);
        -moz-transform: skew(-45deg);
        -ms-transform: skew(-45deg);
        -o-transform: skew(-45deg);
        transform: skew(-45deg);
        top: 0;
        right: 185px;
      }
        html.lte-ie8 .slash > div:first-child > div:after {
          background: url("/i/slash_bg_ie.png") no-repeat 0% 0% transparent;
          right: 0px;
        }

.slogan {
  padding-left: 80px;
}
  .slash_min .slogan {
    margin-top: 22px;
  }
  .slogan:before {
    content: "";
    /*height: 96px;*/
    height: 80px;
    width: 100%;
    /*background: url("/i/logo_.png") no-repeat 0% 0% transparent;*/
    background: url("/i/logo_small__.png") no-repeat 0% 0% transparent;
    background-size: auto 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -7px;
  }

.header_contact {
  top: 0px;
  /*width: 230px*//*280px*//*;*/
  right: 100px;
  margin-top: -4px;
}
  .header_contact .phone {
    color: #f1c40f;
  }

/*
.top_menu2 {
  z-index: 30;
}
  .top_menu2 ul {
    border-spacing: 50px 0px;
    display: inline-block;
    margin-right: -10px;
  }
  .top_menu2 li {
    display: table-cell;
    height: 40px;
    padding: 0px 5px 0px 0px;
    vertical-align: middle;
    background-color: rgba(0,0,0,.75);
  }
    .top_menu2 li:before {
      content: "";
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      left: -40px;
      border-bottom: 40px solid rgba(0,0,0,.75);
      border-left: 40px solid transparent;
    }
    .top_menu2 li:after {
      content: "";
      position: absolute;
      top: 0;
      width: 0;
      height: 0;
      right: -40px;
      border-top: 40px solid rgba(0,0,0,.75);
      border-right: 40px solid transparent;
    }
    .top_menu2 li * {
      color: #fff;
      font-weight: 500;
      padding-bottom: 2px;
      border-bottom: 2px solid transparent;
      cursor: pointer;

      -webkit-transition: all 0.2s ease-out 0s;
      -moz-transition: all 0.2s ease-out 0s;
      -o-transition: all 0.2s ease-out 0s;
      transition: all 0.2s ease-out 0s;
    }
    .top_menu2 li :hover {
      color: #ffc059;
      border-bottom-color: #fff;
    }
    .top_menu2 li .active {
      color: #ffc059;
      font-weight: 700;
      border-bottom-color: #ffc059;
      cursor: default;
    }
*/

.slider_text { height: 250px; margin-top: 26px; }
.slider_text > div.item { width: 640px; position: absolute; left: 0; top: 0; }
.slider_text > .cycle_prev, .slider_text > .cycle_next, .btn-prev[class*="btn"], .btn-next[class*="btn"] {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAAgCAYAAABKHcuvAAABJElEQVR42u2XMQrCQBBF9yg5SK6RU+QSlrZb2lraBUuLNJbbCmk8QixjGfPBhSVE3InLjpA/MGAR+PNm5k+MMcbsNpoEJzjBCU5wghOc4LpZVdVhk+DDMDyR1tpzNvCyLG1d18cpLgLhpNMe34EakoMXRbH3gG3buq7r7uMs0PXc4KjH66O+5OBN01zHLwFwbEBOcOfczesLtOMFPk0Ywuj6igOTJFdum+yAhF7SAg0Tq+1rwmCSg8Pfmj6OsR9+JwcPO4vV1nxfY9tQQ7iBwoseDx5eTqHAzznFaQl0HoKLHg8erlRub4dNn0ff9w94G8/AjsnB/UXX8DdeUdBFDRgAIDFdIeg68H/wt8pHCrqOv6PwG7/OCE5wghOc4AQnOMEV8gVbSDyqmep8JwAAAABJRU5ErkJggg==");
  background-repeat: no-repeat;
  background-position: -34px 50%;
  position: absolute;
  right: 155px;
  bottom: 10px;
  z-index: 110;
  line-height: 20px;
}
  .btn-prev[class*="btn"], .btn-next[class*="btn"] {
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
  }
    .btn-next[class*="btn"] {
      left: auto;
      right: 0;
    }
  html.lte-ie8 .slider_text > .cycle_prev, html.lte-ie8 .slider_text > .cycle_next, html.lte-ie8 .btn-prev[class*="btn"], html.lte-ie8 .btn-next[class*="btn"] {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAAgCAYAAABKHcuvAAAA50lEQVR42u2YLQ4DIRCFOQoH4Rqcgksga5G1yDpSWVuJbYKpQdRRSeU2k5QEtQlLyzbhTTLJujff/AHLGGN6Ugc4wAEOcIADfGpwIYSJMT6MMefRgVtrL6TNOT8MB/fe35aPaa1PI6GLrnPuOhS8Fg8h3EdXPKX07Ex6u6iU8rhURi0/GlwpZYt+zvm1IYa+bHe0WrfXo7ah69rECLSIUQI6lku3U5Wp2iUeGr+fgNMs1S2+xzZf2zVkNIZfB69bnL7/5UyuwRtaHhXHjGOr4xzHzQ139alfZ/gRAXCAAxzgAAc4wPfwN5VIPk/u0JFLAAAAAElFTkSuQmCC");
    background-position: -2px 50%;
  }
  html.lte-ie8 .slider_text > .cycle_next, html.lte-ie8 .btn-next[class*="btn"] {
    right: 160px;
    background-position: -35px 50%;
  }
    html.lte-ie8 .btn-next[class*="btn"] {
      right: auto;
    }
.slider_text > .cycle_prev, .btn-prev[class*="btn"] {
  background-position: -1px 50%;
  right: 200px;
}
  .btn-prev[class*="btn"] {
    right: auto;
  }
.fancybox-close {
  height: 44px !important;
  width: 44px !important;
}
.btn-close {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAgCAYAAADqgqNBAAAAOUlEQVR42u3WMQoAIAwDwDzdn9fVVSyocIHMt2RIkoyLhcPhD+O1BA6HH+HVELjBweH/4T4cHL7TCWDcNDp8LEzbAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: -2px 50%;
  position: absolute;
  left: 10px;
  right: auto;
  top: 4px;
  bottom: auto;
  z-index: 110;
  line-height: 20px;
}

.fancybox-skin {
  border-radius: 0px !important;
}
.fancybox-nav > div {
  visibility: hidden;
  top: 50% !important;
  margin: -15px 7px 0px;
}
  .fancybox-nav:hover > div {
    visibility: visible;
  }

.content h1, h2, .subheader {
  color: #4a4a4a;
  font-size: 28px;
  padding: 32px 0 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}
.content h1:after, h2:after, .subheader:after {
  content: "";
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAKCAYAAACngj4SAAAArElEQVR42mNgQALbd+5kA2JLIA4EYjkGLAAoLgTErlAshEONHNQMkFlsDDgUgRS8AuL/SHgFEItC5ZmBuBqIfyLJ/4SKMUPViEL1IJsBMjMQm2X/ceCTUMt68ajphao5iUdNILKF5/EoBOFSIP6DR/4PVA0+M84zIAXDfwL4CRFqHhKhRoiaFj4hykK6BykRieYYCYnmGFGJhu7ZAkvGDyGQ8T2gGF/GD8GW8QFpmp1wOEQ6RQAAAABJRU5ErkJggg==") center top no-repeat, url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR42mPYvnMnAARUAioZCnyqAAAAAElFTkSuQmCC") center top 5px repeat-x transparent;
  height: 45px;
  display: block;
  margin: 30px 0 0;
}
  .content h1.cw, h2.cw, .subheader[class*="cw"] {
    color: #fff;
  }
    .content h1.cw:after, h2.cw:after, .subheader[class*="cw"]:after {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAKCAYAAACngj4SAAAAp0lEQVR42mNgQAL///9nA2JLIA4EYjkGLAAoLgTErlAshEONHNQMkFlsDDgUgRS8+o8KVgCxKFSeGYirgfgnkvxPqBgzVI0oVA8yAJkZiM0yXOAk1LJePGp6oWpO4lETiGzh+f/4QSkQ/8Ej/weqBh84z4AUDITAEyLUPCRCjRA1LXxClIV0D1IiEs0xEhLNMaISDd2zBZaMH0Ig43tAMb6MH4It4wMAmUlaT2Y/92MAAAAASUVORK5CYII="), url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR42mP4//8/AAX+Av4zEpUUAAAAAElFTkSuQmCC");
    }
html.lte-ie8 h1:after, html.lte-ie8 h2:after, html.lte-ie8 .subheader:after {
  background: url("/i/subheader_line_ie.png") center top no-repeat transparent;
}
html.lte-ie8 h1.cw:after, html.lte-ie8 h2.cw:after, html.lte-ie8 .subheader[class*="cw"]:after {
  background: url("/i/subheader_line_ie_cw.png") center top no-repeat transparent;
}
.content h1 {
  font-size: 30px;
}

.content p:not([class*="bold"]):not([class*="semibold"]) { font-weight: 300; line-height: 160%; }

.we_know_can_do > div {
  width: 345px;
  float: left;
  margin: 0 2px;
  border-bottom: 8px solid transparent;

  -webkit-transition: border-bottom-color 0.2s ease-out 0s;
  -moz-transition: border-bottom-color 0.2s ease-out 0s;
  -o-transition: border-bottom-color 0.2s ease-out 0s;
  transition: border-bottom-color 0.2s ease-out 0s;
}
  .we_know_can_do > div:hover {
    border-bottom-color: #f38612;
  }
  .we_know_can_do > div > .title {
    background-color: #f38612;

    -webkit-transition: background-color 0.2s ease-out 0s;
    -moz-transition: background-color 0.2s ease-out 0s;
    -o-transition: background-color 0.2s ease-out 0s;
    transition: background-color 0.2s ease-out 0s;
  }
    .we_know_can_do > div:hover > .title {
      background-color: #f1a224;
    }
  .we_know_can_do > div > .img {
    height: 345px;
    width: 345px;
    background: url("/i/we_know_can_do.jpg") no-repeat 0% 0% transparent;
  }
    .we_know_can_do > .skill > .img {
      background-position: -345px 0%;
    }
    .we_know_can_do > .doing > .img {
      background-position: -690px 0%;
    }
  .we_know_can_do > div > .text {
    margin: 13px 13px 10px;
    /*font-weight: 500;*/
    line-height: 150%;
    min-height: 40px;
  }

.professional_seo {
  width: 100%;
  background-color: #f38612;
}
  .professional_seo td {
    width: 50%;
    vertical-align: top;
    padding: 0px 80px;
  }
    .professional_seo td:first-child {
      text-align: right;
      border-right: 1px solid #fff;
      padding-bottom: 45px;
    }
    .professional_seo td .title {
      padding-left: 106px;
      height: 100px;
      padding-top: 6px;
      margin-top: 45px;
    }
      .professional_seo td .title:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 106px;
        width: 106px;
        background: url("/i/professional_seo.png") no-repeat top right transparent;
      }
      .professional_seo td:first-child .title:before {
        background-position: left -106px top;
      }
      .professional_seo  tr:first-child td:first-child .title:before {
        background-position: left top;
      }
      .professional_seo tr:first-child td:first-child {
        border-bottom: 1px solid #fff;
      }
      .professional_seo td:first-child > div {
        display: inline-block;
        width: 350px;
      }

.content ul li {
  padding: 6px 15px 7px;
}
  .content ul li:not([class*="bold"]):not([class*="semibold"]) {
    font-weight: 300;
  }
  .content ul li:before {
    content: "";
    height: 7px;
    width: 7px;
    position: absolute;
    left: 0px;
    top: 13px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAANklEQVR42mNgAAJjG+cEIN4BxF+hdAIDksQPIP6PhH+AFUBV/seCdzBAjcIm+ZWgTtx24nMtABAoRKrewMALAAAAAElFTkSuQmCC") no-repeat left center transparent;
  }
  .content ul.ul li:before,
  .content ul ul li:before {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAARElEQVR42mMwtnG2Z8AFgJLbgfgGEGcCMQ+65AQg/g/FH6B8FZhkC5IkMs4HSfYiCXwG4mlArI5s522QSiDmR7cTp2sB84cnGMB/uFEAAAAASUVORK5CYII=");
  }
.content ul.cw li {
  color: #fff;
}
  .content ul.cw li:before {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAANklEQVR42mNgAIKXL18mAPEOIP4KpRMYkCR+APF/JPwDrACq8j8WvIMBahQ2ya8EdeK2E59rAZJyoD8Ag80mAAAAAElFTkSuQmCC");
  }
  .content ul.cw ul li:before, .content ul ul.cw li:before {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAQ0lEQVR42mP4//+/PQMuAJTcDsQ3gDgTiHnQJSf8R4APUL4KTLLlP3aQD5LsRRL4DMTTgFgd2c7bIJVAzI9uJ07XAgBRtWmXx5wd5AAAAABJRU5ErkJggg==");
  }

.work_rules {
  background: url("/i/work_rules.jpg") no-repeat 50% 50% transparent;
  height: 440px;
}
  .work_rules .title {
    padding: 34px 200px;
    text-shadow: 0px 0px 0px #000;
  }
    .work_rules .title:before, .work_rules .title:after {
      content: "";
      border-bottom: 1px solid #fff;
      top: 0px;
      left: 0px;
      position: absolute;
      height: 50%;
      width: 180px;
      display: block;
      padding-bottom: 3px;
    }
    .work_rules .title:after {
      left: auto;
      right: 0px;
    }

  /* work_rules icons */

div.mark_with_line_bkgd
{
	display: inline-block;
	width: 185px;
	margin: 0px -65px 0px 0px;
}
  div.mark_with_line_bkgd, div.mark_with_line_bkgd * {
    text-align: center;
  }

div.mark_up_with_line_bkgd
{
	margin-bottom: 15px;
}

div.mark_down_with_line_bkgd
{
	margin-top: 20px;
}

div.mark_with_line_bkgd div.text
{
	display: table-cell;
	height: 40px;
	vertical-align: middle;
	font-weight: bold;
	position: relative;
	z-index: 5;
	cursor: default;
	text-decoration: none;
  width: 114px;
  color: #fff;
}


div.mark_with_line_bkgd div.line
{
	display: inline-block;
	float: right;
	z-index: 3;
	width: 132px;
	height: 137px;
	margin-left: -100%;
	position: relative;
  right: 2px;
}

div.mark_up_with_line_bkgd div.line
{
	background: scroll url("/i/work_rules/line.png") no-repeat center center transparent;
	margin-top: 69px;
}

div.mark_down_with_line_bkgd div.line
{
	background: scroll url("/i/work_rules/line2.png") no-repeat center center transparent;
	margin-top: 54px;
}

div.mark_with_line_bkgd div.mark_with_point
{
	display: inline-block;
	float: left;
	width: 114px;
	z-index: 3;
	background: transparent;
	position: relative;
}

div.mark_with_line_bkgd div.mark_with_point div.mark
{
	display: inline-block;
	height: 142px;
	width: 114px;
	margin: 0px;
	position: relative;
	z-index: 7;
	background: url("/i/work_rules/all_marks.png") no-repeat 0px 0px transparent;
}

div.mark_with_line_bkgd div.mark_with_point div.mark div.img
{
	display: inline-block;
	vertical-align: middle;
	font-size: 52px;
	font-weight: bold;
	height: 98px;
	position: relative;
	width: 98px;
	color: #494949;
	background:url("/i/work_rules/all_mark_imgs.png") no-repeat 0px 0px transparent;
}

div.mark_with_line_bkgd div.mark_with_point div.mark div.img01
{
	background-position:0px 0px;
}
div.mark_with_line_bkgd div.mark_with_point div.mark div.img02
{
	background-position:0px -98px;
}
div.mark_with_line_bkgd div.mark_with_point div.mark div.img03
{
	background-position:0px -196px;
}
div.mark_with_line_bkgd div.mark_with_point div.mark div.img04
{
	background-position:0px -294px;
}
div.mark_with_line_bkgd div.mark_with_point div.mark div.img05
{
	background-position:0px -392px;
}
div.mark_with_line_bkgd div.mark_with_point div.mark div.img06
{
	background-position:0px -490px;
}

div.mark_up_with_line_bkgd div.mark_with_point div.mark div.img
{
	top: 7px;
}

div.mark_up_with_line_bkgd div.mark_with_point div.mark
{
	margin: 5px 0px 5px 0px;
	background-position: -114px 0px;
}

div.mark_down_with_line_bkgd div.mark_with_point div.mark div.img
{
	top: 35px;
}

div.mark_down_with_line_bkgd div.mark_with_point div.mark
{
	margin: 10px 0px 0px 0px;
	background-position: 0px 0px;
}

div.mark_with_point div.point
{
	background: scroll url("/i/work_rules/point.png") no-repeat 0px 0px transparent;
	display: inline-block;
	height: 28px;
	width: 28px;
	margin: 0px -5px -5px;
	z-index: 5;
	position: relative;
}

.incut {
  z-index: 9;
}
  .incut .arrow {
    background-color: #f39c12;
    height: 16px;
  }
    .incut .arrow:after {
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      top: auto;
      bottom: -9px;
      left: 50%;
      margin-left: -9px;

      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid #f39c12;
    }

.incut2 {
  background-color: #f3f3f3;
  z-index: 9;
}
  .incut2:before {
    content: "";
    width: 50%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 50%;
    background-color: #e9e9e9;
  }
  .incut2 .arrow {
    position: absolute;
    height: 0;
    top: auto;
    bottom: 0;
    width: 100%;
  }
    .incut2 .arrow:before {
      content: "";
      height: 0;
      width: 0;
      left: 50%;
      margin-left: -22px;
      top: auto;
      bottom: -22px;
      position: absolute;

      border-left: 22px solid transparent;
      border-top: 22px solid #f3f3f3;
    }
    .incut2 .arrow:after {
      content: "";
      height: 0;
      width: 0;
      left: 50%;
      top: auto;
      bottom: -22px;
      position: absolute;

      border-right: 22px solid transparent;
      border-top: 22px solid #e9e9e9;
    }
.footer .map {
  background: url("/i/map.jpg") no-repeat 50% 50% transparent;
  padding: 70px 0;
}
  .footer .map > div:before {
    content: "";
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAqCAYAAABC8iBdAAACEUlEQVR42rWYyytFQRzH5yJFHhuvIgvdPMJGNq6VYnU3Htl55JGFje7SQuhGlFJS/ANsrqzsrkcRiW5CYYGF8kgsWHkf39G9ddOZ35mZO+dXn9X8Zj7n3Jn5zZnLmGRYlpUGKkAPmAVB0A3KmInAQMVgBnxbdFyAAMjQkVSBA0s97kG7iqgDvFmJxSJIdhINWOZiSShEQ6PE3KjGlJ0oG9xKdL4DYbAGTsCPRB/ff9mkQ4dNUA88//oVgGnwSfQ9iu+QCV6J5FGJRVUHnokxsmOJnUTSvMIqrifmvC2WtEzsmTTF/bkgGCsSS7gQJExoFINK4lfyMGIDN2iWuAfBeFmMeJJyTVlEMF419Wa1mrIzwXi9vHFL0NivIcoCX4LxxnhCq6AxrCHrIablT5ZLJDQpiDLANVkc+JIkatwj8EqIUsGKQ8kbdNrYPJ5AMyEqAesSBbk91qFUIvkQDAN/9DjqAyHwLnnc+OKf8NhyN/LiZX4XRS92v/+GS7JdO1mZgY8du5gTra4RF2RdIlkK2DMs8zKHvfNiSHQnU35aTH0/yta7oGvzZSNLkqh5VPCam69ybKSDfU3Zjs7py4+hcw1ZQPeuVgRuFGXFiVwOvQrCfRO3Uf6GlxKyIVPXXyfhB8hhpgKDFYJTgWyVmY7ovW7bRuZnbkT0L4tQnOiKFwPmVkS/0MYT2lsa0hp+RInafwEcHz6pyjaw5QAAAABJRU5ErkJggg==") no-repeat 0% 0% transparent;
    height: 42px;
    width: 27px;
    position: absolute;
    top: 5px;
    left: -35px;
  }
.footer .copyright {
  background-color: #000;
  padding: 26px 0;
}

.web_promo_table {
  counter-reset: web_promo_counter;
}
.web_promo_table .title {
  padding-left: 106px;
  height: 106px;
  font-size: 0;
  line-height: 26px;
}
  .web_promo_table .title > div {
    display: table-cell;
    height: 71px;
    vertical-align: middle;
  }
.web_promo_table .title:before {
  counter-increment: web_promo_counter;
  content: counter(web_promo_counter);
  position: absolute;
  font-size: 38px;
  color: #333c43;
  font-weight: bold;
  left: 0;
  top: 0;
  height: 86px;
  width: 83px;
  padding: 21px 20px 0px 24px;
  background: url("/i/web_promo_counter.png") no-repeat top left transparent;
}
  .web_promo_table > div:nth-child(2n) .title:before {
    color: #fff;
    background: url("/i/web_promo_counter_2.png") no-repeat top left transparent;
  }
  .web_promo_table .wpt10 .title:before, .web_promo_table .wpt11 .title:before { padding-left: 13px; }
.web_promo_table > div {
  border-bottom: 4px solid #fff;
}
.web_promo_table > div:first-child {
  border-top: 4px solid #fff;
}
.web_promo_table > div > div {
  display: table;
  width: 1048px;
  margin: 0 auto;
}
.web_promo_table > div > div > div {
  display: table-cell;
}
  .web_promo_table > div > div > div:first-child {
    z-index: 2;
  }
.web_promo_table .wpt01 > div > div, .web_promo_table .wpt03 > div > div, .web_promo_table .wpt05 > div > div, .web_promo_table .wpt07 > div > div, .web_promo_table .wpt09 > div > div, .web_promo_table .wpt11 > div > div { width: 70%; padding: 20px; }

.web_promo_table .wpt01 > div > div:first-child, .web_promo_table .wpt03 > div > div:first-child, .web_promo_table .wpt05 > div > div:first-child, .web_promo_table .wpt07 > div > div:first-child, .web_promo_table .wpt09 > div > div:first-child, .web_promo_table .wpt11 > div > div:first-child { width: 298px; padding: 0; border-left: 4px solid #fff; border-right: 4px solid #fff; }

.web_promo_table .wpt02 > div > div, .web_promo_table .wpt04 > div > div, .web_promo_table .wpt06 > div > div, .web_promo_table .wpt08 > div > div, .web_promo_table .wpt10 > div > div { width: 298px; border-left: 4px solid #fff; border-right: 4px solid #fff; }

.web_promo_table .wpt02 > div > div:first-child, .web_promo_table .wpt04 > div > div:first-child, .web_promo_table .wpt06 > div > div:first-child, .web_promo_table .wpt08 > div > div:first-child, .web_promo_table .wpt10 > div > div:first-child { width: 750px; border: none; display: inline-block; padding: 20px; background: none; }

.web_promo_table .wpt01 > div > div:first-child { background: url("/i/web_promo_01.jpg") no-repeat 50% 0% transparent; background-size: auto 100%; }
.web_promo_table .wpt02 > div > div { background: url("/i/web_promo_02.jpg") no-repeat 50% 0% transparent; background-size: auto 100%; }
.web_promo_table .wpt03 > div > div:first-child { background: url("/i/web_promo_03.jpg") no-repeat 50% 0% transparent; background-size: auto 100%; }
.web_promo_table .wpt04 > div > div { background: url("/i/web_promo_04.jpg") no-repeat 50% 0% transparent; background-size: auto 100%; }
.web_promo_table .wpt05 > div > div:first-child { background: url("/i/web_promo_05.jpg") no-repeat 50% 0% transparent; background-size: auto 100%; }
.web_promo_table .wpt06 > div > div { background: url("/i/web_promo_06.jpg") no-repeat 50% 0% transparent; background-size: auto 100%; }
.web_promo_table .wpt07 > div > div:first-child { background: url("/i/web_promo_07.jpg") no-repeat 50% 0% transparent; background-size: auto 100%; }
.web_promo_table .wpt08 > div > div { background: url("/i/web_promo_08.jpg") no-repeat 50% 0% transparent; background-size: auto 100%; }
.web_promo_table .wpt09 > div > div:first-child { background: url("/i/web_promo_09.jpg") no-repeat 50% 0% transparent; background-size: auto 100%; }
.web_promo_table .wpt10 > div > div { background: url("/i/web_promo_10.jpg") no-repeat 50% 0% transparent; background-size: auto 100%; }
.web_promo_table .wpt11 > div > div:first-child { background: url("/i/web_promo_11.jpg") no-repeat 50% 0% transparent; background-size: auto 100%; }

.site_dev_table .title { padding-left: 0; height: auto; margin-bottom: 20px; }
.site_dev_table .title > div { height: auto; }
.site_dev_table .title:before { content: ""; display: none;}
.site_dev_table .sdt01 > div > div { /*width: 70%;*/ padding: 25px 25px 25px 70px; }
.site_dev_table .sdt01 > div > div:first-child { width: 240px; padding: 0; border-left: 4px solid #fff; border-right: 4px solid #fff; }
.site_dev_table .sdt02 > div > div { width: 370px; border-left: 4px solid #fff; border-right: 4px solid #fff; }
.site_dev_table .sdt02 > div > div:first-child { width: 630px; border: none; display: inline-block; padding: 20px; background: none; }

.site_dev_table .sdt01 > div > div:first-child { background: url("/i/site_dev_01.jpg") no-repeat 50% 50% #fefbec; background-size: 100% auto; }
  .site_dev_table > div > div > div:first-child:after {
    content: "";
    display: block;
    height: 160px;
    width: 160px;
    position: absolute;
    background: url("/i/site_dev_icons.png") no-repeat left top transparent;
    top: 50%;
    margin-top: -53px;
    right: 0;
    margin-right: -108px;
  }
  .site_dev_table .sdt02 > div > div:first-child:after {
    background-position: -160px top;
  }
.site_dev_table .sdt02 > div > div { background: url("/i/site_dev_02.jpg") no-repeat 50% 0% transparent; background-size: auto 100%; }

.content table.price th {
  background-color: #f38612;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}
.content table.price th, .content table.price td {
  padding: 7px 10px;
  vertical-align: middle;
  border-top: 8px solid #ecf0f1;
  border-left: 8px solid #ecf0f1;
}
  .content .bgcw table.price th, .content .bgcw table.price td, .content .bgcorange table.price th, .content .bgcorange table.price td {
    border-top-color: #fff;
    border-left-color: #fff;
  }
  .content table.price tr:first-child th, .content table.price tr:first-child td {
    border-top: none;
  }
  .content table.price th:first-child, .content table.price td:first-child {
    border-left: none;
  }

.content table.price td {
  background-color: #f2d0af;
}
.content table.price_2n td {
  background-color: #f5c797;
}
  .content table.price_2n tr:nth-child(even) td {
    background-color: #f6e8d8;
  }

.portfolio_main_cycle, .portfolio_main_cycle *, .portfolio_main_cycle_pager, .portfolio_main_cycle_pager * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.portfolio_main_cycle_pager .cycle-slideshow { height: 124px; }
.cycle-prev, .cycle-next {
  top: 50%;
  margin-top: -20px;
  width: 25px;
  height: 40px;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP4//9/AwAJfAN+keUJTQAAAABJRU5ErkJggg==') repeat 0% 0% transparent;
}
  .cycle-prev:before, .cycle-next:before {
    content: "";
    display: block;
    position: absolute;
    height: 17px;
    width: 10px;
    top: 50%;
    margin-top: -8px;
    left: 50%;
    margin-left: -5px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAABD0lEQVR42mNgwANcXV1LgbgOiV8HxBUM5ACgxjQg/g/FdVAM42eSalgUEP9BMqALiv8j4ShiDfMD4p9IGucjyc1HEgdZ6EfIMCcg/oakaSMQMyPJM0PFYPIgi51wGWYGxJ+RFO8FYjYs6tigcjB1IAeYoSvSBeK3SIpOAjE/Hp/wQ9XA1H8AmQGTVAHi50iSt4FYlIiwFgXiG0j6XoHMAkkcQ4s9MxJSgxma3pMwF75CErxKgguvorlQHTkMP6CFIQ8ew3iwhKEBNud/IzOWLfGlQ+REvQ5LOlyHlg5dickpyNluDpLcTLScEkhKXkaOvXYoRhaLJbWAyETS3AjFMH4OuUVYBcggJD7I0Gp8egBMGf7+psjkQAAAAABJRU5ErkJggg==') no-repeat 0% 0% transparent;
  }
  .cycle-next:before {
    background-position: 100%  0%;
    margin-left: -4px;
  }
.cycle-pager-prev, .cycle-pager-next {
  top: 50%;
  margin-top: -20px;
  width: 25px;
  height: 40px;
  /*background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNgYGDYDwAAxADA5GrqEwAAAABJRU5ErkJggg==') repeat 0% 0% transparent;*/
  box-shadow: 0px 0px 5px 0px #606060;
  margin-left: -29px;
  margin-right: -29px;
}
  .cycle-pager-prev:before, .cycle-pager-next:before {
    content: "";
    display: block;
    position: absolute;
    height: 17px;
    width: 10px;
    top: 50%;
    margin-top: -8px;
    left: 50%;
    margin-left: -5px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAABD0lEQVR42mNgwAO+fftWCsR1SPw6IK5gIAcANaYB8X8oroNiGD+TVMOigPgPkgFdUPwfCUcRa5gfEP9E0jgfSW4+kjjIQj9Chjl9gwCYpo1AzIwkzwwVg8mDLHbCZZgZEH9GUrwXiNmwqGODysHUgYAZuiJdIH6LpOgkEPPj8Qk/VA1M/QeQGTBJFSB+jiR5G4hFiQhrUSC+gaTvFcgskMQxtNgzIyE1mKHpPQlz4SskwaskuPAqmgvVkcPwA1oY8uAxjAdLGBpgc/43MmPZEl86RE7U67Ckw3Vo6dCVmJyCnO3mIMnNRMspgaTkZeTYa4diZLFYUguITCTNjVAM4+eQW4RVgAxC4oMMrcanBwCM8l+678ufZgAAAABJRU5ErkJggg==') no-repeat 0% 0% transparent;
  }
  .cycle-pager-next:before {
    background-position: 100%  0%;
    margin-left: -4px;
  }
.portfolio_main_cycle {
  height: 380px;
  width: 1044px;
  box-shadow: 0px 0px 6px -1px #606060;
}
  .product-image {
    width: 1034px;
    height: 370px;
    background: url(/i/product_img_sp.jpg) no-repeat 0px 0px transparent;
  }
    .product-image:before {
      content: "Новый\a сайт\a в разработке";
      white-space: pre;
      text-align: right;
      text-transform: uppercase;
      color: #fff;
      font-size: 32px;
      font-weight: 600;
      line-height: 110%;
      display: inline-block;
      position: absolute;
      z-index: 0;
      bottom: 0px;
      right: 50%;
      margin-right: 20px;
      margin-bottom: 15px;
    }
      .product-image-positions:before {
        content: "Новый\a сайт\a на продвижении";
      }
    .product-image img {
      z-index: 9;
    }
  .product-info-section {
    height: 100%;
    width: 50%;
    /*background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNgYGDYDwAAxADA5GrqEwAAAABJRU5ErkJggg==") repeat 0% 0% transparent;*/
  }
    .product-info-section > .descr {
      padding: 10px 35px 0px 10px; /*top right bottom left*/
    }
.portfolio_main_cycle_pager {
  height: 130px;
  width: 1044px;
  box-shadow: 0px 0px 6px -1px #606060;
  border-left-width: 4px;
  border-right-width: 4px;
}
  .portfolio_main_cycle_pager:before, .portfolio_main_cycle_pager:after {
    content: "";
    display: block;
    position: absolute;
    font-size: 0px;
    left: 0px;
    top: 0px;
    width: 3px;
    height: 100%;
    background-color: #fff;
    z-index: 9;
  }
    .portfolio_main_cycle_pager:after {
      left: auto;
      right: 0px;
    }
  .portfolio_main_cycle_pager > .arrow {
    display: block;
    font-size: 0px;
    height: 0px;
    width: 0px;
    border-top: 10px solid #fff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: 0px;
    left: 50%;
    margin-top: 2px;
    margin-left: -9px;
    z-index: 9;
  }
  .portfolio_main_cycle_pager .cycle-carousel-wrap { margin-left: 444px !important; }
  .portfolio_main_cycle_pager .item {
    padding: 2px 3px;

    -webkit-transition: opacity 0.2s ease-out 0s;
    -moz-transition: opacity 0.2s ease-out 0s;
    -o-transition: opacity 0.2s ease-out 0s;
    transition: opacity 0.2s ease-out 0s;
  }
  .portfolio_main_cycle_pager .item img {
    width: 142px;
    height: 120px;
  }
.portfolio_main_cycle_pager .item, .portfolio_main_cycle_pager .cycle-slideshow:hover .item { opacity: 0.5 !important; }
  .portfolio_main_cycle_pager .cycle-slide-active, .portfolio_main_cycle_pager .cycle-slideshow .item:hover { opacity: 1 !important; }


.gallery-block-container > div:first-child {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-end;

  padding: 0px 0px 13px;
  margin: -10px 0em 0em;
  position: relative;
}
  .gallery-item {
    box-sizing: border-box;
    padding: 20px 10px 10px;
    position: relative;
  }
    .gallery-item-width-1 {
      width: 880px;
    }
    .gallery-item-width-2 {
      width: 430px;
    }
    .gallery-item-width-3 {
      width: 286px;
    }

    .gallery-item-notice {
      background: #fff;
      font-size: 16px;
      font-weight: 300;
      line-height: 160%;
      padding: 7px 13px 10px;
      margin: 0px;
      text-align: left;
    }
    .gallery-item-image {
      background: #f38612;
      font-size: 0px;
      padding: 5px;
    }
      .gallery-item-image a,
      .gallery-item-image span {
        font-size: 0px;
      }
      .gallery-item-image a,
      .gallery-item-image a img {
        cursor: pointer;
      }
      .gallery-item-image img {
        width: 100%;
      }

.gallery-notice {
  font-size: 16px;
  font-weight: 300;
  padding: 0px 0px 20px;
  margin: 0px;
  position: relative;
  text-align: center;
}


.site-order-checkboxlist > div > div:nth-child(2n+1) {
  background: #eaeaea;
}
.site-order-checkboxlist label {
  padding-left: 22px;
  cursor: pointer;
  display: table-cell;
  vertical-align: middle;
  z-index: 9;
}
.site-order-checkboxlist label:before {
  content: "";
  display: block;
  left: 0px;
  top: 1px;
  position: absolute;
  font-size: 0px;
  height: 11px;
  width: 11px;
  background-color: transparent;
  border: 3px solid #f38612;
}
  .site-order-checkboxlist input:checked + label:before {
    background-color: #666;
  }
  .site-order-checkboxlist .description_info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 0px;
    height: 0px;
    margin: auto;
    visibility: hidden;
  }
    .site-order-checkboxlist label:hover + .description_info {
      visibility: visible;
    }
    .site-order-checkboxlist .description_info * {
      font-size: 12px;
    }
    .site-order-checkboxlist .description_info > div {
      background-color: #eaeaea;
      font-size: 0px;
      min-width: 150px;
      max-width: 150px;
      padding: 8px;
      margin-left: 10px;
      text-align: center;
    }
      .site-order-checkboxlist .description_info > div:before {
        content: "";
        display: block;
        position: absolute;
        height: 0px;
        width: 0px;
        top: 50%;
        left: -10px;
        margin-top: -10px;
        border-top: 10px solid transparent;
        border-right: 10px solid #eaeaea;
        border-bottom: 10px solid transparent;
      }

.contacts-map #YMapsID [class*="areas-pane"] * {
  position: absolute;
}

.manager_photo {
  width: 170px;
  height: 170px;
  background: no-repeat center top;
  background-size: 100% auto;
}

.fancybox:before {
  content: "";
  display: block;
  position: absolute;
  cursor: pointer;
  border-radius: 3px;
  background: url("/i/image_zoom.png") no-repeat 0% 100% rgba(200, 200, 200, .6);
  background-size: 100% 100%;
  height: 40px;
  width: 40px;
  top: auto;
  left: 0%;
  bottom: 0%;
  margin-left: 10px;
  margin-bottom: 10px;
  z-index: 500;

  -webkit-transition: height 0.2s ease-out 0s, width 0.2s ease-out 0s;
  -moz-transition: height 0.2s ease-out 0s, width 0.2s ease-out 0s;
  -o-transition: height 0.2s ease-out 0s, width 0.2s ease-out 0s;
  transition: height 0.2s ease-out 0s, width 0.2s ease-out 0s;
}
  .fancybox:hover:before {
    height: 60px;
    width: 60px;
  }

.faq-submenu-item-name:before {
  content: "+";
  display: table-cell;
  position: absolute;
  left: 0px;
  font-weight: bold;
  color: #c1272c;
  height: 100%;
  vertical-align: middle;
  top: 0px;
  padding-top: 2px;
}
  .faq-submenu-item-name.active:before {
    content: "\2013"; /* &ndash; */
    vertical-align: middle;
  }